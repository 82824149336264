<!-- 活动 -->
<template>
  <div class="activitu-box">
    <div class="present">
      <div class="redLine">当前活动</div>
      <!-- 未开始的 -->
      <div v-for="(item,index) in noStarList" :key="index" class="list">
        <p class="title">名称：{{ item.activity_name }}</p>
        <p class="contont">
          <span>发起人：{{ item.sponsor_name }}</span>
          <span>报名人数：{{ item.player_count }}</span>
          <span>讲师积分：{{ item.speaker_credits }}</span>
          <span>讲师学分：{{ item.speaker_score }}</span>
          <span>员工积分：{{ item.player_credits }}</span>
          <span>员工学分：{{ item.player_score }}</span>
        </p>
        <p class="contont">
          <span>开始时间：{{ item.start_time }}</span>
        </p>
        <p class="contont">
          <span>地址：{{ item.activity_space }}</span>
        </p>
        <!-- <div v-show="item.images" class="img-box">
          <span>活动照片</span>
          <img v-for="(item1,index1) in item.images" :key="index1" :src="item1" alt="">
        </div> -->
        <p class="btn">
          <span v-if="!(item.is_join !== 0 || item.is_baoming !== 0)" class="red" @click="join(item)">报名</span>
          <span v-else>已报名</span>
        </p>
      </div>
      <!-- 进行中的 -->
      <div v-for="(item,index) in list" :key="index" class="list">
        <p class="title">名称：{{ item.activity_name }}</p>
        <p class="contont">
          <span>发起人：{{ item.sponsor_name }}</span>
          <span>报名人数：{{ item.player_count }}</span>
          <span>讲师积分：{{ item.speaker_credits }}</span>
          <span>讲师学分：{{ item.speaker_score }}</span>
          <span>员工积分：{{ item.player_credits }}</span>
          <span>员工学分：{{ item.player_score }}</span>
        </p>
        <p class="contont">
          <span>开始时间：{{ item.start_time }}</span>
        </p>
        <p class="contont">
          <span>地址：{{ item.activity_space }}</span>
        </p>
        <!-- <div v-show="item.images" class="img-box">
          <span>活动照片</span>
          <img v-for="(item1,index1) in item.images" :key="index1" :src="item1" alt="">
        </div> -->
        <p class="btn">
          <span v-if="item.is_join===0">进行中</span>
          <span v-else>已报名</span>
        </p>
      </div>
    </div>

    <!-- 已结束的 -->
    <div class="present">
      <div class="redLine">以往活动</div>
      <div v-for="(item,index) in old_list" :key="index" class="list">
        <p class="title">名称：{{ item.activity_name }}</p>
        <p class="contont">
          <span>发起人：{{ item.sponsor_name }}</span>
          <span>报名人数：{{ item.player_count }}</span>
          <span>讲师积分：{{ item.speaker_credits }}</span>
          <span>讲师学分：{{ item.speaker_score }}</span>
          <span>员工积分：{{ item.player_credits }}</span>
          <span>员工学分：{{ item.player_score }}</span>
        </p>
        <p class="contont">
          <span>开始时间：{{ item.start_time }}</span>
        </p>
        <p class="contont">
          <span>地址：{{ item.activity_space }}</span>
        </p>
        <!-- <div v-show="item.images" class="img-box">
          <span>活动照片</span>
          <img v-for="(item1,index1) in item.images" :key="index1" :src="item1" alt="">
        </div> -->
        <p class="btn">
          <span v-if="item.is_join===0">已结束</span>
          <span v-else>已报名</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { activityList, activityJoin } from '@/api/activity.js'
export default {
  data() {
    return {
      noStarList: [],
      list: [],
      old_list: []
    }
  },
  created() {
    this.noStar()
    this.activity()
    this.old_activity()
  },
  methods: {
    noStar() {
      const info = {
        page: 1,
        page_size: 999,
        status: 0
      }
      activityList(info).then(res => {
        this.noStarList = res.result.list
      })
    },
    activity() {
      const info = {
        page: 1,
        page_size: 999,
        status: 1
      }
      activityList(info).then(res => {
        this.list = res.result.list
      })
    },
    old_activity() {
      const info = {
        page: 1,
        page_size: 999,
        status: 2
      }
      activityList(info).then(res => {
        this.old_list = res.result.list
      })
    },
    // 报名
    join(item) {
      const info = {
        activity_id: item.id
      }
      activityJoin(info).then(res => {
        if (res.errNo === 0) {
          this.$message.success('报名成功')
          this.noStar()
          this.activity()
          this.old_activity()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.activitu-box{
  background-color: #e5e5e5;
  margin-top: 30px;
  padding: 30px 0 0 0;
  width: 100%;
  .present{
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 20px;
    background-color: #fff;
  }
  .redLine{
    font-size: 16px !important;
    font-weight: 800;
    position: relative;
    margin: 50px 0 0 40px;
    text-align: left;
  }
  .redLine::before{
    margin-top: 0 !important;
    content: '';
    position: absolute;
    left: -20px;
    height: 18px;
    top: 2px;
    width: 4px;
    background: #ee2e2e;
  }
  .list{
    // background-color: yellow;
    margin: 0 20px 0 20px;
    border-bottom: 1px solid #DDDDDD;
    .title{
      text-align: left;
      font-weight: bold;
      margin-top: 30px;
    }
    .contont{
      margin-top: 15px;
      text-align: left;
      span{
        margin-right: 10px;
        color: #999;
      }
    }
    .img-box{
      text-align: left;
      margin-top: 15px;
      display: flex;
      span{
        font-weight: bold;
        display: inline-block;
        height: 100px;
        margin-right: 15px;
      }
      img{
        margin-right: 15px;
        width: 100px;
        height: 100px;
      }
    }
    .btn{
      text-align: right;
      span{
        text-align: center;
        margin: 0 30px 20px 0;
        display: inline-block;
        width: 90px;
        height: 30px;
        line-height: 30px;
        background-color: #999;
        border-radius: 20px;
        color: white;
      }
      .red{
        text-align: center;
        margin: 0 30px 20px 0;
        display: inline-block;
        width: 90px;
        height: 30px;
        line-height: 30px;
        background-color: #ee2e2e;
        border-radius: 20px;
        color: white;
        cursor: pointer;
      }
    }
  }
}
</style>
