import request from '@/utils/request'
// 活动列表
export function activityList(params) {
  return request({
    url: 'activity/activity/index',
    params: params,
    method: 'get'
  })
}
// 活动报名
export function activityJoin(params) {
  return request({
    url: 'activity/activity/join',
    params: params,
    method: 'get'
  })
}
